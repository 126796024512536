<template>
  <CFooter>
    <!--div>
      <a href="https://coreui.io" target="_blank">CoreUI</a>
      <span class="ms-1"
        >&copy; {{ new Date().getFullYear() }} creativeLabs.</span
      >
    </div-->
    <div class="ms-auto">
      <a href="https://blitzkabine.at/">Blitzkabine INT. GmbH</a>
    </div>
  </CFooter>
</template>

<script>
export default {
  name: 'AppFooter',
}
</script>
