<template>
  <div>
    <router-view @error="handleError" @toast="showToast" />
    <ErrorModal ref="errorModal" modal-id="error-modal" />
    <CToaster placement="top-end">
      <CToast
        :key="index"
        v-for="(toast, index) in toasts"
        :color="toast.color ? toast.color : 'default'"
      >
        <CToastHeader closeButton>
          <span class="me-auto fw-bold">
            {{ toast.title }}
          </span>
        </CToastHeader>
        <CToastBody>
          {{ toast.content }}
        </CToastBody>
      </CToast>
    </CToaster>
  </div>
</template>

<style lang="scss">
// Import Main styles for this application
@import 'styles/style';
</style>

<script>
import api from './api/index'
import ErrorModal from '@/views/modals/ErrorModal'
import router from '@/router'

export default {
  components: {
    ErrorModal,
  },
  data() {
    return {
      toasts: [],
    }
  },
  watch: {
    $route(to, from) {
      api
        .testConnection()
        .then((isConnected) => {
          if (isConnected) {
            if (to.fullPath === '/github') {
              router.push(from)
              window.open('https://gitlab.blitzkabine.com')
            } else if (to.fullPath === '/stripe') {
              router.push(from)
              window.open('https://dashboard.stripe.com/login')
            } else if (to.fullPath === '/paypal') {
              router.push(from)
              window.open('https://www.paypal.com/at/signin')
            }
          }
        })
        .catch(() => {
          router.push('/login')
        })
    },
  },
  methods: {
    handleError(err) {
      this.$refs.errorModal.handle(err)
    },
    showToast(toast) {
      this.toasts.push(toast)
    },
  },
  mounted() {},
}
</script>
