export default (isAdmin) => {
  if (isAdmin) {
    return [
      {
        component: 'CNavItem',
        name: 'Aufträge',
        to: '/orders',
        icon: 'cil-list',
      },
      {
        component: 'CNavItem',
        name: 'Kunden',
        to: '/customers',
        icon: 'cil-user',
      },
      {
        component: 'CNavItem',
        name: 'Übersicht',
        to: '/dashboard',
        icon: 'cil-chart-pie',
      },
      {
        component: 'CNavTitle',
        name: 'VERWALTUNG',
      },
      {
        component: 'CNavGroup',
        name: 'Mailversand',
        icon: 'cil-envelope-closed',
        items: [
          {
            component: 'CNavItem',
            name: 'Verlinkungen',
            to: '/mail/mail-link',
          },
          {
            component: 'CNavItem',
            name: 'Vorlagen',
            to: '/mail/templates',
          },
          {
            component: 'CNavItem',
            name: 'Protokoll',
            to: '/mail/protocol',
          },
        ],
      },
      {
        component: 'CNavItem',
        name: 'Gutscheine',
        to: '/voucher',
        icon: 'cil-notes',
      },
      {
        component: 'CNavItem',
        name: 'Globale Variablen',
        to: '/config',
        icon: 'cil-settings',
      },
      {
        component: 'CNavItem',
        name: 'Benutzer',
        to: '/users',
        icon: 'cil-user',
      },
      {
        component: 'CNavTitle',
        name: 'LINKS',
      },
      {
        component: 'CNavItem',
        name: 'Git',
        to: 'github',
        icon: 'cib-github',
      },
      {
        component: 'CNavItem',
        name: 'Stripe',
        to: 'stripe',
        icon: 'cib-cc-stripe',
      },
      {
        component: 'CNavItem',
        name: 'PayPal',
        to: 'paypal',
        icon: 'cib-cc-paypal',
      },
    ]
  } else {
    return [
      {
        component: 'CNavItem',
        name: 'Aufträge',
        to: '/orders',
        icon: 'cil-list',
      },
      {
        component: 'CNavItem',
        name: 'Kunden',
        to: '/customers',
        icon: 'cil-user',
      },
    ]
  }
}
