export const logoBigstrips = [
  '180 35',
  `
  <title>B!GSTRIPS Logo</title>
      <defs>
        <style>.a{fill:#fff;}.b{fill:#28231c;}</style>
      </defs>
      <g transform="translate(43.372 -137.834)">
        <path class="a" d="M119.982,521.037v-22.2h-5.739V503.2h0v17.838Z" transform="translate(-136.005 -361)"/>
        <path class="a" d="M114.243,568.159v4.6h5.739V568.39h0v-.231Z" transform="translate(-136.005 -406.399)"/>
        <path class="a" d="M70.651,527.5v1.392c0,3.871-3.147,6.689-7.545,6.689H51.628V511.475H62.462c4.4,0,7.58,2.547,7.58,6.01v1.732a4.719,4.719,0,0,1-1.931,3.735A5.675,5.675,0,0,1,70.651,527.5ZM57.17,516.059v5.025h5.4a1.828,1.828,0,0,0,1.931-1.8v-1.7a1.743,1.743,0,0,0-1.9-1.528Zm7.938,11.375a1.776,1.776,0,0,0-1.9-1.8H57.17V531h6.007a1.839,1.839,0,0,0,1.931-1.867Z" transform="translate(-95 -369.278)"/>
        <path class="a" d="M149.607,523.915v4.738h3.024a5,5,0,0,1-3.311,1.278c-3.191,0-5.692-3.09-5.692-7.033s2.5-7.034,5.692-7.034c2.913,0,5.249,2.574,5.635,6.022h5.569a12.662,12.662,0,0,0-3.194-7.724,10.85,10.85,0,0,0-16.02,0,13.43,13.43,0,0,0,0,17.47,10.856,10.856,0,0,0,14.578,1.323v2.061h4.676v-11.1H149.607Z" transform="translate(-151.615 -368.726)"/><g transform="translate(13.272 141.782)"><path class="b" d="M215.749,527.345V526.5h5.7v1.183c0,1.825,2.317,2.941,4.929,2.941,1.913,0,4.156-.777,4.156-2.7,0-4.5-14.235-1.825-14.235-10.445,0-3.888,2.869-7.2,9.674-7.2,6.657,0,9.858,3.042,9.858,7.3v1.116h-5.7V517.44c0-1.453-1.875-2.434-4.23-2.434-2.133,0-3.862.777-3.862,2.332,0,3.55,14.235,1.657,14.235,10.344,0,4.293-3.531,7.605-10.042,7.605C219.685,535.289,215.749,532.01,215.749,527.345Z" transform="translate(-215.749 -510.274)"/>
        <path class="b" d="M298.478,516.475h-6.952v19.268h-5.7V516.475H278.8v-4.732h19.679Z" transform="translate(-257.038 -511.236)"/>
        <path class="b" d="M352.3,527.09h-4.6v8.654H342v-24h11c5.149,0,8.571,2.7,8.571,6.93v1.488a6.309,6.309,0,0,1-3.678,5.882l4.009,9.7h-5.922Zm-4.6-4.732h5.664c1.545,0,2.5-.71,2.5-1.961v-1.961c0-1.25-.956-1.961-2.465-1.961h-5.7Z" transform="translate(-298.425 -511.236)"/>
        <path class="b" d="M406.961,511.743h5.7v24h-5.7Z" transform="translate(-340.967 -511.236)"/>
        <path class="b" d="M452.87,518.74v2.231c0,4.225-3.384,7-8.534,7H439v7.775h-5.7v-24h11C449.449,511.743,452.87,514.515,452.87,518.74Zm-5.7-.236c0-1.25-.956-2.028-2.465-2.028H439v6.761h5.664c1.545,0,2.5-.777,2.5-2.028Z" transform="translate(-358.217 -511.236)"/>
        <path class="b" d="M493.334,527.345V526.5h5.7v1.183c0,1.825,2.317,2.941,4.929,2.941,1.913,0,4.156-.777,4.156-2.7,0-4.5-14.235-1.825-14.235-10.445,0-3.888,2.869-7.2,9.674-7.2,6.658,0,9.858,3.042,9.858,7.3v1.116h-5.7V517.44c0-1.453-1.876-2.434-4.23-2.434-2.133,0-3.862.777-3.862,2.332,0,3.55,14.235,1.657,14.235,10.344,0,4.293-3.531,7.605-10.042,7.605C497.27,535.289,493.334,532.01,493.334,527.345Z" transform="translate(-397.53 -510.274)"/></g></g>
  </g>
`,
]
