import { createStore } from 'vuex'
import VuexPersistence from 'vuex-persist'

const vuexLocal = new VuexPersistence({
  storage: window.localStorage,
})

export default createStore({
  state: {
    sidebarVisible: '',
    sidebarUnfoldable: false,
    username: undefined,
    password: undefined,
    token: undefined,
    loginDenialMessage: '',
  },
  mutations: {
    toggleSidebar(state) {
      state.sidebarVisible = !state.sidebarVisible
    },
    toggleUnfoldable(state) {
      state.sidebarUnfoldable = !state.sidebarUnfoldable
    },
    updateSidebarVisible(state, payload) {
      state.sidebarVisible = payload.value
    },
    updateUsername(state, username) {
      state.username = username
    },
    updatePassword(state, password) {
      state.password = password
    },
    updateToken(state, token) {
      state.token = token
    },
    updateLoginDenialMessage(state, loginDenialMessage) {
      state.loginDenialMessage = loginDenialMessage
    },
  },
  actions: {},
  modules: {},
  getters: {
    getUsername(state) {
      return state.username
    },
    getPassword(state) {
      return state.password
    },
    getToken(state) {
      return state.token
    },
    getLoginDenialMessage(state) {
      return state.loginDenialMessage
    },
  },
  plugins: [vuexLocal.plugin],
})
